import React from "react";
import type { HeadFC } from "gatsby";
import { BlogLayout } from "../../../components/layout";
import { PageHead } from "../../../components/page-head";

import PortugueseBrazil from "./pt-br";
import PortuguesePortugal from "./pt";
import { useIntl } from "react-intl";

const PageContent = {
  "pt-br": <PortugueseBrazil />,
  pt: <PortuguesePortugal />,
};

const HuntingPage = () => {
  const { locale } = useIntl();

  return <BlogLayout>{PageContent[locale]}</BlogLayout>;
};

export default HuntingPage;

export const Head: HeadFC = () => (
  <PageHead pageTitle="Data Marketplace - Hunting" />
);
