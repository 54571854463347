import * as React from "react";
import { strong, Button, Heading, Paragraph } from "grommet";

const HuntingPage = () => {
  return (
    <>
      <Heading level={2} style={{color: "#3600b2"}}>Hunting</Heading>
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Nossa área de Hunting</strong> tem a missão de <strong style={{color:"#3600b2"}}>apoiar</strong> de forma ágil e profissional nossos <strong style={{color:"#3600b2"}}>clientes e parceiros</strong> nessa difícil <strong style={{color:"#3600b2"}}>busca por talentos</strong>.
      </Paragraph>
      <Paragraph fill style={{marginBottom: "25px"}}>
        Após uma rigorosa <strong style={{color:"#3600b2"}}>triagem comportamental, técnica, e psicológica,</strong> realizada pelo nosso time de Recrutamento e Seleção, conseguimos atender à necessidades muito especificas encontrando o <strong style={{color:"#3600b2"}}>candidato ideal para sua necessidade</strong>.
      </Paragraph>

      <Paragraph fill style={{color: "#3600b2"}}>
      <b>Etapas do Processo de Hunting:</b>
          <ul>

          <li><strong style={{color:"#3600b2"}}>Compreensão da Estrutura do Cliente</strong></li>
            <Paragraph fill>
            De fundamental importância, esta primeira etapa nos permite compreender a <strong style={{color:"#3600b2"}}>estrutura do contratante, seus objetivos, sua cultura e seu posicionamento.</strong> Permite-nos também analisar o <strong style={{color:"#3600b2"}}>funcionamento do negócio e suas peculiaridades.</strong>
            </Paragraph>

            <li style={{marginTop: "25px"}}><strong>Definição do Cargo e Perfil do Candidato</strong></li>
            <Paragraph fill>
            Nesta etapa, são analisados os <strong style={{color:"#3600b2"}}>detalhes da vaga</strong>, bem como são determinados os <strong style={{color:"#3600b2"}}>requisitos técnicos e comportamentais</strong> dessa, além de ser definido o <strong style={{color:"#3600b2"}}>pacote de remuneração e o prazo para conclusão do processo</strong>.
            </Paragraph>

            <li style={{marginTop: "25px"}}><strong>Lista dos Candidatos Finalistas</strong></li>
            <Paragraph fill>
            A lista final composta de <strong style={{color:"#3600b2"}}>até 03 candidatos qualificados por vaga</strong> será enviada para entrevista final do contratante.
            </Paragraph>
          </ul>
      </Paragraph>
      <Paragraph fill style={{color: "#3600b2"}}>
      <b>Condições Gerais:</b>
      </Paragraph>
      <Paragraph fill>
        Com o <strong style={{color:"#3600b2"}}>envio das informações</strong> relativas ao <strong style={{color:"#3600b2"}}>perfil do candidato e cargo, ocorre a abertura oficial da vaga</strong> a ser preenchida, havendo, a partir desse momento, a <strong style={{color:"#3600b2"}}>obrigatoriedade de realização do serviço e recebimento dos respectivos honorário,</strong> mediante o <strong style={{color:"#3600b2"}}>sucesso da contratação.</strong>
      </Paragraph>
      <Paragraph fill>
        A <strong style={{color:"#3600b2"}}>contratante</strong> poderá <strong style={{color:"#3600b2"}}>cancelar</strong> a abertura da vaga <strong style={{color:"#3600b2"}}>mediante formalização</strong> por sistema, sem nenhum custo à <strong style={{color:"#3600b2"}}>contratante</strong>, porém, <strong style={{color:"#3600b2"}}>havendo a contratação</strong> de <strong style={{color:"#3600b2"}}>candidato apresentado</strong> pela <strong style={{color:"#3600b2"}}>Data Marketplace</strong> à <strong style={{color:"#3600b2"}}>contratante</strong> dentro do <strong style={{color:"#3600b2"}}>prazo de 12 (Doze) meses,</strong> será devida a <strong style={{color:"#3600b2"}}>totalidade dos honorários tratados nessa proposta,</strong> seja a contratação feita formalmente, por intermédio de solicitação à <strong style={{color:"#3600b2"}}>Data Marketplace</strong> ou informalmente.
      </Paragraph>
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Não é permitido apresentar candidato</strong>, apresentado pela <strong style={{color:"#3600b2"}}>Data Marketplace</strong> para outras empresas, a qualquer título, e o <strong style={{color:"#3600b2"}}>não atendimento dessa obrigação</strong> gera o dever de a <strong style={{color:"#3600b2"}}>contratante pagar 100% dos honorários aqui tratados</strong>.
      </Paragraph>

      <Paragraph fill style={{color: "#3600b2"}}>
      <b>Responsabilidades da Contratante:</b>
      </Paragraph>
      <ul>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Divulgar</strong> à <strong style={{color:"#3600b2"}}>Data Marketplace</strong> todas as <strong style={{color:"#3600b2"}}>informações</strong> que se fizerem <strong style={{color:"#3600b2"}}>necessárias</strong> para o bom <strong style={{color:"#3600b2"}}>desenvolvimento dos serviços ora contratados</strong>;</li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Fornecer</strong>, em tempo hábil para a realização das atividades previstas, <strong style={{color:"#3600b2"}}>todos os dados e elementos informativos necessários</strong> ao desenvolvimento e execução dos serviços por parte da <strong style={{color:"#3600b2"}}>Data Marketplace</strong>;</li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Fornecer as informações essenciais</strong> que possam ser <strong style={{color:"#3600b2"}}>compartilhadas com o candidato</strong>, assim como as informações necessárias para <strong style={{color:"#3600b2"}}>identificar o candidato ideal</strong> para a vaga (perfil, competências exigidas, local de trabalho etc.);</li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Manter sigilo sobre a identidade dos candidatos</strong> apresentados;</li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Ter agilidade e flexibilidade de agenda para entrevistar os candidatos</strong> apresentados;</li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Fornecer feedback sobre os candidatos à Data Marketplace</strong> em até 40 horas após cada entrevista;</li>
          <li style={{marginBottom: "10px"}}>Ter <strong style={{color:"#3600b2"}}>disponibilidade para reunião</strong> com o RH e/ou requisitante para <strong style={{color:"#3600b2"}}>alinhamento do perfil;</strong></li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Dar retorno</strong> referente a <strong style={{color:"#3600b2"}}>proposta a ser realizada ao candidato</strong> em até 24 horas <strong style={{color:"#3600b2"}}>após a aprovação;</strong></li>
          <li style={{marginBottom: "10px"}}><strong style={{color:"#3600b2"}}>Pagar os honorários</strong> devidos na <strong style={{color:"#3600b2"}}>forma e no prazo da presente proposta;</strong></li>
      </ul>

      <Paragraph fill style={{color: "#3600b2"}}>
      <b>Garantias</b>
      </Paragraph>
      <Paragraph fill>
        Se, por qualquer motivo, o <strong style={{color:"#3600b2"}}>candidato contratado</strong>, avaliado e validado pela <strong style={{color:"#3600b2"}}>contratante</strong>, deixar a empresa durante <strong style={{color:"#3600b2"}}>primeiros 90 dias de trabalho</strong>, nos <strong style={{color:"#3600b2"}}>comprometemos a iniciar um novo processo de busca, sem custo adicional,</strong> desde que para <strong style={{color:"#3600b2"}}>candidatos de perfil e remuneração semelhantes e com exclusividade para a vaga citada.</strong> Esta <strong style={{color:"#3600b2"}}>garantia</strong> pode ser utilizada <strong style={{color:"#3600b2"}}>uma vez</strong>, durante seu <strong style={{color:"#3600b2"}}>período de vigência</strong> e, para usá-la, <strong style={{color:"#3600b2"}}>não pode a contratante mudar o perfil da vaga</strong>.
        <br/>
        <ul>
        <li style={{marginTop: "25px"}}><strong style={{color:"#3600b2"}}>Prazo de validade dos candidatos apresentados</strong></li>
        
        <Paragraph fill>
          Durante o <strong style={{color:"#3600b2"}}>prazo de 12 (Doze) meses</strong> após o <strong style={{color:"#3600b2"}}>envio de cada lista</strong> (candidatos apresentados), a contratação de qualquer profissional nela indicado ou por empresas do mesmo grupo ou a ela vinculadas, dará à <strong style={{color:"#3600b2"}}>Data Marketplace</strong> o direito de <strong style={{color:"#3600b2"}}>cobrar os respectivos honorários totais previstos</strong> na <strong style={{color:"#3600b2"}}>proposta</strong> que tiver <strong style={{color:"#3600b2"}}>dado origem à respectiva lista</strong>, os quais serão devidos em <strong style={{color:"#3600b2"}}>parcela única</strong>.
        </Paragraph>
        <Paragraph fill>
          <strong style={{color:"#3600b2"}}>Considera-se empresa vinculada à contratante</strong>, qualquer empresário individual ou pessoa jurídica que tenha <strong style={{color:"#3600b2"}}>recebido valores da contratante</strong>, mesmo que a título de “ajuda de custo”, no <strong style={{color:"#3600b2"}}>período ora referido</strong>.
        </Paragraph>
        </ul>
      </Paragraph>
      <Paragraph fill>
        A <strong style={{color:"#3600b2"}}>Data Marketplace</strong> se compromete-se a <strong style={{color:"#3600b2"}}>manter sigilo</strong> sobre <strong style={{color:"#3600b2"}}>todas as informações e dados confidenciais a que tiver acesso</strong> em virtude do <strong style={{color:"#3600b2"}}>trabalho a ser realizado</strong>, reconhecendo-os como <strong style={{color:"#3600b2"}}>uso reservado da contratante</strong>.
      </Paragraph>
      <Paragraph fill style={{textAlign: "center"}}>
          <a href="/contact"><Button type="submit" label="FALE COM NOSSO TIME" primary size="small" style={{width: "250px", margin: "10px"}} /></a>{" "}
          <a href="/contact"><Button type="submit" label="CONTRATAR" primary size="small" style={{width: "250px"}}/></a>
      </Paragraph>
    </>
  );
};

export default HuntingPage;
